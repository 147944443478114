import "../../../qelpcare-call-center-application/src/main.scss";
import "./defaults.scss";
import "./claro-br.scss";

import { QelpCare } from "../../../qelpcare-call-center-application/src/QelpCare";
import { ItemByFilter } from "../../../qelpcare-call-center-application/src/widgets/ItemByFilter";
import { async } from "regenerator-runtime";
import { Accordion } from "../../../qelpcare-call-center-application/src/widgets/Accordion";
import { Tabs } from "../../../qelpcare-call-center-application/src/widgets/Tabs";
import { DeviceImage } from "../../../qelpcare-call-center-application/src/widgets/DeviceImage";
import { Qlickr } from "../../../qelpcare-call-center-application/src/widgets/Qlickr";
import { CopyURI } from "../../../qelpcare-call-center-application/src/widgets/CopyURI";
import { SMSSender } from "../../../qelpcare-call-center-application/src/widgets/SMSSender";
import { WhatsAppInterface } from "../../../qelpcare-call-center-application/src/widgets/WhatsAppInterface";
import { SendMail } from "../../../qelpcare-call-center-application/src/widgets/SendMail";
import { StepsNavigation } from "../../../qelpcare-call-center-application/src/widgets/StepsNavigation";
import { SimpleNavigate } from "../../../qelpcare-call-center-application/src/widgets/SimpleNavigate";
import { MostViewedUsecases } from "../../../qelpcare-call-center-application/src/widgets/MostViewedUsecases";
import { Faq } from "../../../qelpcare-call-center-application/src/widgets/Faq";

var Handlebars = require("handlebars/runtime");
require("./utilities.js");

class App extends QelpCare {
  constructor() {
    super(".application");
    this.pages.home = async () => {
      let content = {};
      let aside = {};
      let side_index_itens = {};
      let manufacturers = await this.call("manufacturers");

      for (let element of this.settings.contents.home.main_content) {
        try {
          if (element.endpoint) {
            let result;
            if (typeof element.endpoint === "function") {
              result = await element.endpoint();
            } else {
              result = await this.call(element.endpoint);
            }
            if (element.widget === "apps") {
              result = result.filter((item) => {
                return !item.type[0]?.name?.match(/smartphone/gi);
              });
            }
            element.content = Object.assign(result, element?.content || {});
            element.assets = this.settings.assets;
          }
          if (element.widget == "most_viewed_devices") {
            element.content.forEach((item) => {
              item.manufacturerName = manufacturers.find((fitem) => {
                return fitem.path == item.manufacturer;
              }).name;
            });
          }
          let html = this.settings.templates.widgets[element.widget](element);
          content[element.widget] = {
            html: html,
          };
        } catch (error) {
          console.warn(error);
        }
        side_index_itens[element.widget] = element;
      }
      for (let element of this.settings.contents.home.aside_panel) {
        try {
          if (element.endpoint) {
            let result = await this.call(element.endpoint);
            element.content = result;
            element.assets = this.settings.assets;
          }
          let html = this.settings.templates.widgets[element.widget](element);
          aside[element.widget] = {
            html: html,
          };
        } catch (error) {
          console.warn(error);
        }
      }
      this.html.head(this.translate("html/title"));
      this.render(
        this.settings.templates.pages.home({
          side_index: this.settings.templates.widgets.side_index({
            list: side_index_itens,
          }),
          content: content,
          aside_panel: aside,
        }),
        () => {
          this.initiate.side_index();
          this.widgets.register(
            "manufacturers",
            new SimpleNavigate("manufacturers")
          );
          this.widgets.register(
            "most_viewed_devices",
            new SimpleNavigate("most_viewed_devices")
          );
          this.widgets.register(
            "most_viewed_usecases",
            new MostViewedUsecases("most_viewed_usecases")
          );
          this.widgets.register("categories", new SimpleNavigate("categories"));
          this.widgets.register(
            "device_types",
            new SimpleNavigate("device_types")
          );
          this.widgets.register(
            "device_groups",
            new SimpleNavigate("device_groups")
          );
          this.widgets.register("apps", new SimpleNavigate("apps"));
          this.widgets.register("faq", new Faq("faq"));

          let emulator_btn = this.element.querySelector(".device-emulator-btn");
          if (emulator_btn) {
            emulator_btn.addEventListener("click", (e) => {
              this.router.navigateTo("emulator");
            });
          }
        }
      );
    };

  }
}
const app = new App();

app.config({
  name: "Claro Brasil - CSR",
  assets: "https://assets.qelplatam.com/",
  locale: "pt-br",
  localization: require("./assets/json/localization.json"),
  environment: {
    live: "https://configuraraparelhos.claro.com.br/",
  },
  api: [
    {
      test: /csr-claro-br-accept.qelplatam.com/,
      set: "https://sxsb4c9hj9.execute-api.sa-east-1.amazonaws.com/dev",
    },
    {
      test: /localhost:9015/,
      set: "https://sxsb4c9hj9.execute-api.sa-east-1.amazonaws.com/dev",
    },
    {
      test: /claro-br.local/,
      set: "https://sxsb4c9hj9.execute-api.sa-east-1.amazonaws.com/dev",
    },
    {
      test: /configuraraparelhos.claro.com.br/,
      set: "https://uh6gj9jjpf.execute-api.sa-east-1.amazonaws.com/prod",
    },
    {
      test: /claro-br-staging.qelplatam.com/,
      set: "https://uh6gj9jjpf.execute-api.sa-east-1.amazonaws.com/prod",
    },
  ],
  root: [
    { test: /localhost:9015/, set: "//localhost:9015/" },
    {
      test: /csr-claro-br-accept.qelplatam.com/,
      set: "//csr-claro-br-accept.qelplatam.com/",
    },
    { test: /claro-br.local/, set: "/acheaqui/" },
    { test: /configuraraparelhos.claro.com.br/, set: "/acheaqui/" },
    { test: /claro-br-staging.qelplatam.com/, set: "/acheaqui/" },
  ],
  routes: {
    application: "aplicativos",
    categories: "recursos",
    type: "tipo",
    search_results: "resultados",
    manufacturers: "fabricantes",
    oss: "sistema-operacional",
  },
  wrappers: [
    "smartphone",
    "smartwatch",
    "tablet",
    "fixed-phone",
    "telefone-fixo",
  ],
  smssender: {
    endpoint: "https://uh6gj9jjpf.execute-api.sa-east-1.amazonaws.com/prod/api-csr/send/sms",
  },
  whatsapp: {
    endpoint: "",
  },
  sendmail: {
    endpoint: "",
  },
  contents: {
    home: {
      main_content: [
        {
          name: "Aparelhos mais vistos",
          widget: "most_viewed_devices",
          endpoint: "devices/popular",
        },
        {
          name: "Tipos de aparelhos",
          widget: "device_types",
          endpoint: "devicetypes",
        },
        {
          name: "Fabricantes",
          widget: "manufacturers",
          endpoint: "manufacturers",
        },
        {
          name: "Recursos",
          widget: "categories",
          endpoint: "categories/popular",
        },
        {
          name: "Aplicativos",
          widget: "apps",
          endpoint: "softwares/popular",
        },
        {
          name: "Sistema Operacional",
          widget: "sistemas_operacionais",
          endpoint: "softwares",
        },
      ],
      aside_panel: [
        {
          name: "Tópicos mais vistos",
          widget: "most_viewed_usecases",
          endpoint: "topics/popular",
        },
      ],
    },
  },
  templates: {
    pages: {
      home: require("../../../qelpcare-call-center-application/src/templates/pages/home.handlebars"),
      softwares: require("../../../qelpcare-call-center-application/src/templates/pages/softwares.handlebars"),
      smartphone: require("../../../qelpcare-call-center-application/src/templates/pages/smartphone.handlebars"),
      app: require("../../../qelpcare-call-center-application/src/templates/pages/app.handlebars"),
      os: require("../../../qelpcare-call-center-application/src/templates/pages/os.handlebars"),
      manufacturers: require("../../../qelpcare-call-center-application/src/templates/pages/manufacturers.handlebars"),
      device_type: require("../../../qelpcare-call-center-application/src/templates/pages/device_type.handlebars"),
      device_group: require("./templates/pages/device_group.handlebars"),
      claro_tv: require("./templates/pages/claro_tv.handlebars"),
      brand: require("../../../qelpcare-call-center-application/src/templates/pages/brand.handlebars"),
      device: require("./templates/pages/device.handlebars"),
      usecase: require("../../../qelpcare-call-center-application/src/templates/pages/usecase.handlebars"),
      categories: require("./templates/pages/categories.handlebars"),
      category: require("./templates/pages/category.handlebars"),
      tv: require("../../../qelpcare-call-center-application/src/templates/pages/tv.handlebars"),
      dts: require("../../../qelpcare-call-center-application/src/templates/pages/dts.handlebars"),
      search_results: require("../../../qelpcare-call-center-application/src/templates/pages/search_results.handlebars"),
    },
    widgets: {
      breadcrumbs: require("../../../qelpcare-call-center-application/src/templates/widgets/breadcrumbs.handlebars"),
      choices: require("../../../qelpcare-call-center-application/src/templates/widgets/choices.handlebars"),
      steps_navigation: require("../../../qelpcare-call-center-application/src/templates/widgets/steps_navigation.handlebars"),
      share_topic: require("./templates/widgets/share_topic.handlebars"),
      sistemas_operacionais: require("./templates/widgets/sistemas-operacionais.handlebars"),
      side_index: require("../../../qelpcare-call-center-application/src/templates/widgets/side_index.handlebars"),
      most_viewed_devices: require("../../../qelpcare-call-center-application/src/templates/widgets/most_viewed_devices.handlebars"),
      most_viewed_apps: require("../../../qelpcare-call-center-application/src/templates/widgets/most_viewed_apps.handlebars"),
      most_viewed_usecases: require("../../../qelpcare-call-center-application/src/templates/widgets/most_viewed_usecases.handlebars"),
      most_viewed_usecases_oss: require("./templates/widgets/most_viewed_usecases_oss.handlebars"),
      device_types: require("./templates/widgets/device_types.handlebars"),
      device_groups: require("./templates/widgets/device_groups.handlebars"),
      manufacturers: require("../../../qelpcare-call-center-application/src/templates/widgets/manufacturers.handlebars"),
      manufacturer_list: require("../../../qelpcare-call-center-application/src/templates/widgets/manufacturer_list.handlebars"),
      categories: require("./templates/widgets/categories.handlebars"),
      devices_by_brand: require("../../../qelpcare-call-center-application/src/templates/widgets/devices_by_brand.handlebars"),
      specific_device: require("./templates/widgets/specific_device.handlebars"),
      dts_devices_by_brand: require("../../../qelpcare-call-center-application/src/templates/widgets/dts_devices_by_brand.handlebars"),
      dts_software_by_os: require("../../../qelpcare-call-center-application/src/templates/widgets/dts_software_by_os.handlebars"),
      decode_by_controllers: require("../../../qelpcare-call-center-application/src/templates/widgets/decode_by_controllers.handlebars"),
      software_by_os: require("../../../qelpcare-call-center-application/src/templates/widgets/software_by_os.handlebars"),
      categories_tutorials: require("./templates/widgets/categories_tutorials.handlebars"),
      categories_tutorials_app: require("../../../qelpcare-call-center-application/src/templates/widgets/categories_tutorials_app.handlebars"),
      categories_tutorials_oss: require("./templates/widgets/categories_tutorials_oss.handlebars"),
      apps: require("../../../qelpcare-call-center-application/src/templates/widgets/apps.handlebars"),
      card_filter: require("../../../qelpcare-call-center-application/src/templates/widgets/card_filter.handlebars"),
    },
  },
});
app.ready(() => {
  console.log("ready");

  app.element.addEventListener("SMSSenderInitiated", (e) => {
    console.log(e);
    
    var SPMaskBehavior = function (val) {
      return val.replace(/\D/g, "").length === 11
        ? "(00) 00000-0000"
        : "(00) 0000-00000";
    };
    var spOptions = {
      placeholder: "(00) 0000-00000",
      onKeyPress: function (val, e, field, options) {
        field.mask(SPMaskBehavior.apply({}, arguments), options);
      },
    };
    $(".widget.sms-sender input[name='phone']").mask(
      SPMaskBehavior,
      spOptions
    );
  });
});
