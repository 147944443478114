import { Widgets } from "./Widgets";
export class StepsNavigation extends Widgets {
    constructor(target, navigator, usecase) {
        super(navigator)
        this.target = document.querySelector(`.widget.${target}`);
        this.scroll_element = document.querySelector(".page.usecase .wrapper");
        this.current_step = this.target.querySelector(".usecase-step-wrapper");
        this.steps = this.target.querySelectorAll(".usecase-step-wrapper");
        this.progress_bar = this.element.querySelector(".steps-navigation-progress .progress-bar");
        this.controls = this.element.querySelectorAll(".steps-navigation-controls span");
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.controls.forEach(item =>{
            item.addEventListener("click", e=>{
                this.controlsClicked(e);
            })
        })
        
        for (let i = 0; i < usecase.steps.length; i++) {
            const progress_step = document.createElement("div");
            const current = i+1;
            const progress = document.createElement("div");
            progress.classList.add("progress")
            progress.innerHTML = `<span class="current">${current}</span>/<span>${usecase.steps.length}</span>`;
            progress_step.append(progress)
            progress_step.classList.add("progress-bar-step", "step-"+i)
            progress_step.dataset.step = i;

            progress_step.addEventListener("click", e => {
                this.gotoStep(e.currentTarget.dataset.step);
            });

            this.progress_bar.append(progress_step);
        };
        this.update(false);
    }
    init() {
        document.addEventListener("keydown", this.handleKeyDown);
    }
    unmount() {
        document.removeEventListener("keydown", this.handleKeyDown)
    }
    handleKeyDown(e) {
        if (e.code=="ArrowRight") {
            this.nextStep();
        }
        if (e.code=="ArrowLeft") {
            this.prevStep()
        }
    }
    controlsClicked(e) {
        const elem = e.currentTarget;
        if (elem.classList.contains("btn-prev")) {
            this.prevStep();
        } else {
            this.nextStep();
        }
    }
    nextStep() {
        this.current_step = this.current_step.nextElementSibling || this.current_step;
        this.update()
    }
    prevStep() {
        this.current_step = this.current_step.previousElementSibling || this.current_step;
        this.update()
    }
    gotoStep(number) {
        this.current_step = this.target.querySelector(`.usecase-step-wrapper[data-step="${number}"]`);
        this.update()
    }
    update(initialized=true) {
        let status = "read";
        for (let i = 0; i < this.steps.length; i++) {
            this.steps[i].classList.remove("focus");
            const progress = this.progress_bar.querySelector(`.progress-bar-step.step-${i}`);
            progress.classList.remove("on", "read", "unread");
            if (this.steps[i]==this.current_step) {
                progress.classList.add("on")
                status = "unread"
            } else {
                progress.classList.add(status)
            }
        }
        if (initialized) { 
            this.scrollItemIntoView(this.current_step)
        } else {
            this.steps[0].classList.add("focus");
        };
    }
	scrollItemIntoView(item) {
		const elementTop = this.progress_bar.getBoundingClientRect().top + window.scrollY;
		const offsetTop = elementTop - 90;
		window.scrollTo({ top: offsetTop, behavior: 'smooth' });

		item.classList.add("focus");
		const rect = item.getBoundingClientRect();
		var leftPosition = (rect.width * item.dataset.step);
		if (leftPosition<0)	leftPosition = 0;

		this.target.scrollTo({
			"left": leftPosition,
			"behavior": "smooth"
		});
	}    
}