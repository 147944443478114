import { Widgets } from "./Widgets";

export class ResultsFilter extends Widgets {
    constructor(selector, target) {
        super(selector);
        this.filters = this.element.querySelectorAll(".filter-item");
        this.target = document.querySelector(target);
    }
    init() {
        this.filters.forEach(item => {
            item.addEventListener("click", e => {
                this.filterItems(e);
            })
        })
    }
    filterItems(e) {
        console.log(e);
        this.filters.forEach(item=>{
            item.classList.remove("active");
        })
        const target = e.currentTarget
        const tofilter = target.dataset.filter;
        target.classList.add("active");

        const results = this.target.querySelectorAll(".result-group")

        if (tofilter=="all") {
            results.forEach(item => {
                item.classList.remove("not-selected")
            })
        } else {
            results.forEach(item => {
                if (item.classList.contains(`result-${tofilter}`)) {
                    item.classList.remove("not-selected")
                } else {
                    item.classList.add("not-selected")
                }
            })
        }
    }
}