var Handlebars = require("../../../../CSR_QelpCare_Projects/csr-claro-br/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li class=\"card "
    + alias4(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":6,"column":28},"end":{"line":6,"column":36}}}) : helper)))
    + "\" data-path=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":6,"column":49},"end":{"line":6,"column":57}}}) : helper)))
    + "\">\n                <a class=\"wrapper\">\n                    <div class=\"image\">\n                        <img src=\""
    + alias4(container.lambda((depths[1] != null ? lookupProperty(depths[1],"assets") : depths[1]), depth0))
    + "manufacturers/70x70/"
    + alias4(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":9,"column":67},"end":{"line":9,"column":75}}}) : helper)))
    + ".png\" alt=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":9,"column":86},"end":{"line":9,"column":94}}}) : helper)))
    + "\">\n                    </div>\n                    <div class=\"details\">\n                        <label class=\"title\">\n                            "
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":13,"column":28},"end":{"line":13,"column":36}}}) : helper)))
    + "\n                        </label>\n                        <p class=\"total-devices\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"deviceNumber") || (depth0 != null ? lookupProperty(depth0,"deviceNumber") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"deviceNumber","hash":{},"data":data,"loc":{"start":{"line":15,"column":49},"end":{"line":15,"column":65}}}) : helper)))
    + " "
    + alias4((lookupProperty(helpers,"pluralize")||(depth0 && lookupProperty(depth0,"pluralize"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"deviceNumber") : depth0),"widget/dts-devices-by-brand/total-devices",{"name":"pluralize","hash":{},"data":data,"loc":{"start":{"line":15,"column":66},"end":{"line":15,"column":136}}}))
    + "</p>\n                    </div>\n                    <div class=\"icon-holder\">\n                        <i class=\"material-icons\">chevron_right</i>\n                    </div>\n                </a>\n            </li>\n";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li class=\"card device "
    + alias4(((helper = (helper = lookupProperty(helpers,"manufacturerPath") || (depth0 != null ? lookupProperty(depth0,"manufacturerPath") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"manufacturerPath","hash":{},"data":data,"loc":{"start":{"line":28,"column":35},"end":{"line":28,"column":55}}}) : helper)))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":28,"column":56},"end":{"line":28,"column":64}}}) : helper)))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"os") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":65},"end":{"line":28,"column":95}}})) != null ? stack1 : "")
    + "\">\n                <a class=\"wrapper\" href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"root") || (depth0 != null ? lookupProperty(depth0,"root") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"root","hash":{},"data":data,"loc":{"start":{"line":29,"column":41},"end":{"line":29,"column":49}}}) : helper)))
    + alias4(((helper = (helper = lookupProperty(helpers,"manufacturerPath") || (depth0 != null ? lookupProperty(depth0,"manufacturerPath") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"manufacturerPath","hash":{},"data":data,"loc":{"start":{"line":29,"column":49},"end":{"line":29,"column":69}}}) : helper)))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":29,"column":70},"end":{"line":29,"column":78}}}) : helper)))
    + "/"
    + alias4(alias5((depths[1] != null ? lookupProperty(depths[1],"category") : depths[1]), depth0))
    + "/"
    + alias4(alias5((depths[1] != null ? lookupProperty(depths[1],"topic") : depths[1]), depth0))
    + "\"\n                    data-navigate-to=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"manufacturerPath") || (depth0 != null ? lookupProperty(depth0,"manufacturerPath") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"manufacturerPath","hash":{},"data":data,"loc":{"start":{"line":30,"column":38},"end":{"line":30,"column":58}}}) : helper)))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":30,"column":59},"end":{"line":30,"column":67}}}) : helper)))
    + "/"
    + alias4(alias5((depths[1] != null ? lookupProperty(depths[1],"category") : depths[1]), depth0))
    + "/"
    + alias4(alias5((depths[1] != null ? lookupProperty(depths[1],"topic") : depths[1]), depth0))
    + "\">\n                    <div class=\"image\">\n                        <img src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"coverImageUrl") || (depth0 != null ? lookupProperty(depth0,"coverImageUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"coverImageUrl","hash":{},"data":data,"loc":{"start":{"line":32,"column":34},"end":{"line":32,"column":51}}}) : helper)))
    + "\" alt=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":32,"column":58},"end":{"line":32,"column":66}}}) : helper)))
    + "\">\n                    </div>\n                    <div class=\"details\">\n                        <span class=\"brand\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"manufacturerName") || (depth0 != null ? lookupProperty(depth0,"manufacturerName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"manufacturerName","hash":{},"data":data,"loc":{"start":{"line":35,"column":44},"end":{"line":35,"column":64}}}) : helper)))
    + "</span>\n                        <span class=\"type\">"
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"type") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</span>\n                        <h3 class=\"title\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":37,"column":42},"end":{"line":37,"column":50}}}) : helper)))
    + "</h3>\n                    </div>\n                </a>\n            </li>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"os") : depth0)) != null ? lookupProperty(stack1,"path") : stack1), depth0))
    + " ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- Widget Devices by Brand -->\n<div class=\"widget devices-by-brand dts-item-by-filter\">\n    <div class=\"filter widget manufacturer-list filter-list\">\n        <ul class=\"panel\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"brands") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":22,"column":21}}})) != null ? stack1 : "")
    + "        </ul>\n    </div>\n    <div class=\"devices widget device-list item-list\">\n        <ul class=\"panel\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"devices") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":12},"end":{"line":41,"column":21}}})) != null ? stack1 : "")
    + "        </ul>\n    </div>\n</div>\n<!-- End Widget Devices by Brand -->";
},"useData":true,"useDepths":true});