export class Widgets {
    constructor(selector) {
        if (!selector) {
            return false;
        }
        try {
            if (selector instanceof HTMLElement) {
                this.element = selector;
            } else {
                this.element = document.querySelector(`.widget.${selector}`);
            }
            
            if (!this.element) {
                console.warn(`${selector} not found. Maybe the CSS class widget is missing.`);
            }
        } catch (e) {
            console.warn(e);
            console.log(`.widget.${selector}`);
        }

        this.selector = selector;
        this.qelpcare = window.QelpCareCSR;
        this.parent = null;
    }
    init() {
        if (process.env.NODE_ENV === 'development') console.log("widget initiated");
    }
    unmount() {
        if (process.env.NODE_ENV === 'development') console.log("widget destroyed");
    }
    getCSSVariable(varName) {
        return getComputedStyle(document.documentElement).getPropertyValue(varName).trim();
    }
    getCSSVariableNumber(varName) {
        return Number(this.getCSSVariable(varName).replace(/[^0-9\.\,\-]/g, ""));
    }
    navigateTo(e) {
        let uri = e.currentTarget.dataset.navigateTo;
        this.parent.loading.start();
        this.parent.router.navigateTo(uri);
    }
    simpleNavigate(element) {
        if (!element) {
            element = this.element;
        }
        if (!element) return;
        element.querySelectorAll("[data-navigate-to]").forEach(elem => {
            elem.addEventListener("click", e => {
                if (!e.shiftKey && !e.ctrlKey) {
                    e.preventDefault();
                    this.navigateTo(e);
                }
            })
        });
    }
    convertHREF() {
        this.element.querySelectorAll("a").forEach(elem => {
            elem.addEventListener("click", e => {
                e.preventDefault();
                this.parent.router.navigateTo(e.currentTarget.href.toString().split(this.parent.router.router)[1]);
            })
        });
    }
    wrappDeviceImage(elem) {
        let hardware = elem.querySelector(".hardware");
        let startscreen = elem.querySelector(".startScreen");
        let wrapper = elem.querySelector(".image-wrapper");
        if (hardware) {
            if (Number(elem.dataset.screenSizeX) > 0 && Number(elem.dataset.screenSizeY) > 0) {
                startscreen.style.width = elem.dataset.screenSizeX + "px";
                startscreen.style.height = elem.dataset.screenSizeY + "px";
                startscreen.style.left = elem.dataset.screenPositionX + "px";
                startscreen.style.top = elem.dataset.screenPositionY + "px";
            }

            let scale = 1;
            if (Number(elem.dataset.imageSizeX) > Number(elem.offsetWidth)
                && Number(elem.dataset.imageSizeY) > Number(elem.offsetHeight)) {
                if (Number(elem.dataset.imageSizeX) > Number(elem.dataset.imageSizeY)) {
                    scale = Number(elem.offsetWidth) / Number(elem.dataset.imageSizeX);
                } else {
                    scale = elem.offsetHeight / elem.dataset.imageSizeY;
                }
            } else if (Number(elem.dataset.imageSizeX) > Number(elem.offsetWidth)) {
                scale = Number(elem.offsetWidth) / Number(elem.dataset.imageSizeX);
            } else {
                scale = Number(elem.offsetHeight) / Number(elem.dataset.imageSizeY);
            }
            if (scale > 1) scale = 1;
            wrapper.style.transform = `scale(${scale})`;
            wrapper.style.left = Math.floor((Number(elem.offsetWidth) / 2) - (Number(elem.dataset.imageSizeX * scale) / 2)) + "px";
            wrapper.style.top = Math.floor((Number(elem.offsetHeight) / 2) - (Number(elem.dataset.imageSizeY * scale) / 2)) + "px";
            wrapper.style.minHeight = (Number(elem.dataset.imageSizeY) + 15) + "px";
            let event = new Event("WrappedImageResized");
            event.scale = scale;
            this.element.dispatchEvent(event, { bubbles: true });
        } else {
            let startscreen = elem.querySelector(".startScreen");
            if (!startscreen.naturalWidth) {
                startscreen.addEventListener("load", e => {
                    this.wrappit(e.currentTarget, elem, wrapper);
                });
            } else {
                this.wrappit(startscreen, elem, wrapper);
            }
        }
    }
    wrappit(target, elem, wrapper) {
        let scale = 1;
        if (Number(target.naturalWidth) > Number(elem.offsetWidth)
            && Number(target.naturalHeight) > Number(elem.offsetHeight)) {
            if (Number(target.naturalWidth) > Number(target.naturalHeight)) {
                scale = Number(elem.offsetWidth) / Number(target.naturalWidth);
            } else {
                scale = elem.offsetHeight / target.naturalHeight;
            }
        } else if (Number(target.naturalWidth) > Number(elem.offsetWidth)) {
            scale = Number(elem.offsetWidth) / Number(target.naturalWidth);
        } else {
            scale = Number(elem.offsetHeight) / Number(target.naturalHeight);
        }
        if (scale > 1) scale = 1;
        wrapper.style.transform = `scale(${scale})`;
        wrapper.style.left = Math.floor((Number(elem.offsetWidth) / 2) - (Number(target.naturalWidth * scale) / 2)) + "px";
        wrapper.style.top = Math.floor((Number(elem.offsetHeight) / 2) - (Number(target.naturalHeight * scale) / 2)) + "px";
        let event = new Event("WrappedImageResized");
        event.scale = scale;
        this.element.dispatchEvent(event, { bubbles: true });

    }
}