import { SMSSender } from "./SMSSender";
import { Widgets } from "./Widgets";

export class ShareTopic extends Widgets {
    constructor(selector, params) {
        super(selector)
        this.params = params;
        this.element.addEventListener("click", e=>{
            this.openShareTopicModal(e)
        })
    }
    openShareTopicModal(e) {
        console.log(e);
        this.modal = document.createElement("div");
        this.modal.classList.add("modal","widget","sms-sender");
        this.modal.innerHTML = `
<div class="alert">
    <div class="header">
        <span>
            ${this.parent.translate("widget/share_topic/title")}
        </span>
        <span class="material-icons-outlined close-btn">
            close
        </span>
    </div>
    <div class="body">
        <p class="device-name">${this.params.device?.name}</p>
        <p class="topic-name">${this.params.usecase?.topicName}</p>
    </div>
    <div class="footer">
        <label for="phone">${this.parent.translate("widget/share_topic/phone")}</label>
        <input name="phone">
        <span class="error-message"></span>
        <button class="primary send-btn">Enviar SMS</button>
        <div class="sms-sent">
            <span class="material-icons-outlined">
                mark_email_read
            </span>
            <span>
                ${this.parent.translate("widget/share_topic/messages/sms_sent")}
            </span>
        </div>
    </div>
</div>
`;
        document.body.append(this.modal);
        document.body.classList.add("scroll-lock");
        const sms_sender = this.parent.widgets.register('sms_sender', new SMSSender("sms-sender"));
        sms_sender.element.addEventListener("SMSSenderClosed", e => {
            document.body.classList.remove("scroll-lock");
        })
    }
}