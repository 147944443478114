import { Widgets } from "./Widgets";
import Fuse from 'fuse.js';


export class Buscador extends Widgets {
    constructor() {
        super("qelp-search");
        this.form = this.element.querySelector("form");
        this.input = this.element.querySelector("input[id='search']");
        this.button = this.element.querySelector("button");
        this.suggestions = this.element.querySelector(".qclatam-suggestions-list");
        this.completions;
        this.loading = false;
        this.form.addEventListener("submit", e=> {
            e.preventDefault()
        })
        this.button.addEventListener("click", e => {
            this.submitSearch();
        });
        this.input?.addEventListener("keyup", e=> {
            e.preventDefault();
            switch (e.key) {
                case "ArrowUp":
                    this.nextSuggetionItem("ArrowUp");
                    break;
                case "ArrowDown":
                    this.nextSuggetionItem("ArrowDown");
                    break;
                case "Escape":
                    this.suggestions.classList.remove("active");
                        break;
                case "Enter":
                    const suggestion = this.suggestions.querySelector("li.active");
                    if (this.suggestions.classList.contains("active") && suggestion) {
                        this.updateInputField(e);
                    }
                    this.submitSearch();
                    break;      
                default:
                    this.checkCompletions(e);
                    break;
            }
        })
    }
    submitSearch() {
        this.suggestions.classList.remove("active");
        const terms = encodeURIComponent(this.input.value);
        if (terms.length==0) return;
        this.input.value="";
        const url = this.parent.getRouteByPath("search_results");
        this.parent.router.navigateTo(`${url}?q=${terms}`);
    }
    nextSuggetionItem(direction) {
        var current = this.suggestions.querySelector("li.active");
        var next;
        if (!current) {
            next = this.suggestions.querySelector("li:first-child");
            next.classList.add("active");
        } else {
            current.classList.remove("active");
            next = direction=="ArrowDown" ? current.nextElementSibling : current.previousElementSibling
            next.classList.add("active");
        }
    }
    updateInputField(e) {
        var suggestion;
        console.log(e)

        if (e.type=="click") {
            suggestion = e.currentTarget;
        } else {
            suggestion = this.suggestions.querySelector("li.active");
        }
       
        this.input.value = suggestion.dataset.term
        this.suggestions.classList.remove("active");
    }
    async checkCompletions(e) {
        // Clear any pending debounce timers
        clearTimeout(this.debounceTimer);
    
        // Set a debounce delay (adjust as needed)
        this.debounceTimer = setTimeout(async () => {
            const query = this.input.value.trim();
            if (!query) {
                this.suggestions.classList.remove("active");
                return;
            }
            if (this.loading) return;
    
            this.loading = true;
            const currentQuery = query;
    
            // Fetch autocomplete data
            const response = await this.parent.postData("autocomplete", { term: query });
            this.loading = false;
    
            // Abort if input was cleared while awaiting the response
            if (!this.input.value.trim()) return;
    
            // Process the response results
            this.completions = response.results.map(term => ({ term }));
            console.log(this.completions);
    
            // Configure Fuse.js with project-specific options
            const fuseOptions = {
                includeScore: true,
                keys: ['term'],
                threshold: 0.7,
                distance: 10
            };
            const fuse = new Fuse(this.completions, fuseOptions);
            const searchResults = fuse.search(query);
    
            // Build the suggestions list (limit to 10 suggestions)
            const suggestionsList = document.createElement("ul");
            const maxSuggestions = 10;
            let count = 0;
    
            for (const result of searchResults) {
                if (count >= maxSuggestions) break;
                const li = document.createElement("li");
                li.dataset.term = result.item.term;
                li.innerHTML = '<em class="material-icons-outlined">search</em>' + result.item.term;
                li.addEventListener("click", e => {
                    this.updateInputField(e);
                    this.submitSearch();
                });
                suggestionsList.append(li);
                count++;
            }
    
            // Display suggestions if any exist; otherwise, hide the suggestions panel.
            if (count > 0) {
                this.suggestions.innerHTML = "";
                this.suggestions.append(suggestionsList);
                this.suggestions.classList.add("active");
            } else {
                this.suggestions.classList.remove("active");
            }
        }, 300); // Debounce delay in milliseconds
    }    
}
