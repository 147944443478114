var Handlebars = require("../../../../CSR_QelpCare_Projects/csr-claro-br/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li class=\"filter-item filter-"
    + alias4(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":17,"column":50},"end":{"line":17,"column":58}}}) : helper)))
    + "\" data-filter=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":17,"column":73},"end":{"line":17,"column":81}}}) : helper)))
    + "\">\n                        <span>"
    + alias4((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,(lookupProperty(helpers,"concat")||(depth0 && lookupProperty(depth0,"concat"))||alias2).call(alias1,"page/search_results/results-filter/",(data && lookupProperty(data,"key")),{"name":"concat","hash":{},"data":data,"loc":{"start":{"line":18,"column":42},"end":{"line":18,"column":93}}}),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":18,"column":30},"end":{"line":18,"column":95}}}))
    + "</span>\n                    </li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"result-group result-topics\">\n                <ul>\n                    <h3>"
    + container.escapeExpression((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(alias1,"page/search_results/topics",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":26,"column":24},"end":{"line":26,"column":66}}}))
    + "</h3>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"topics") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":20},"end":{"line":39,"column":29}}})) != null ? stack1 : "")
    + "                </ul>\n            </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li>\n                        <span class=\"material-icons-outlined content_copy\" data-href=\"f/"
    + alias4(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":29,"column":88},"end":{"line":29,"column":96}}}) : helper)))
    + "\">\n                            content_copy\n                        </span>\n                        <a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"root") || (depth0 != null ? lookupProperty(depth0,"root") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"root","hash":{},"data":data,"loc":{"start":{"line":32,"column":33},"end":{"line":32,"column":41}}}) : helper)))
    + "f/"
    + alias4(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":32,"column":43},"end":{"line":32,"column":51}}}) : helper)))
    + "\">\n                            <span class=\"topic-name\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":33,"column":53},"end":{"line":33,"column":61}}}) : helper)))
    + "</span>\n                            <span class=\"material-icons-outlined\">\n                                chevron_right\n                            </span>\n                        </a>\n                    </li>\n";
},"6":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <ul class=\"result-group result-manufacturers\">\n                    <h3>"
    + container.escapeExpression((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(alias1,"page/search_results/manufacturers",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":46,"column":24},"end":{"line":46,"column":73}}}))
    + "</h3>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"manufacturers") : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":20},"end":{"line":56,"column":29}}})) != null ? stack1 : "")
    + "                </ul>\n";
},"7":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li>\n                        <a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"root") || (depth0 != null ? lookupProperty(depth0,"root") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"root","hash":{},"data":data,"loc":{"start":{"line":49,"column":33},"end":{"line":49,"column":41}}}) : helper)))
    + alias4((lookupProperty(helpers,"route")||(depth0 && lookupProperty(depth0,"route"))||alias2).call(alias1," manufacturers",{"name":"route","hash":{},"data":data,"loc":{"start":{"line":49,"column":41},"end":{"line":49,"column":67}}}))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":49,"column":68},"end":{"line":49,"column":76}}}) : helper)))
    + "\">\n                            <i class=\"image\">\n                                <img src=\""
    + alias4(container.lambda((depths[1] != null ? lookupProperty(depths[1],"assets") : depths[1]), depth0))
    + "manufacturers/64x64/"
    + alias4(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":51,"column":75},"end":{"line":51,"column":83}}}) : helper)))
    + ".png\" alt=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":51,"column":94},"end":{"line":51,"column":102}}}) : helper)))
    + "\">\n                            </i>\n                            <span>"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":53,"column":34},"end":{"line":53,"column":42}}}) : helper)))
    + "</span>\n                        </a>\n                    </li>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <ul class=\"result-group result-softwares\">\n                    <h3>"
    + container.escapeExpression((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(alias1,"page/search_results/softwares",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":61,"column":24},"end":{"line":61,"column":69}}}))
    + "</h3>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"softwares") : depth0),{"name":"each","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":62,"column":20},"end":{"line":70,"column":29}}})) != null ? stack1 : "")
    + "                </ul>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li>\n                        <a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"root") || (depth0 != null ? lookupProperty(depth0,"root") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"root","hash":{},"data":data,"loc":{"start":{"line":64,"column":33},"end":{"line":64,"column":41}}}) : helper)))
    + alias4(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":64,"column":41},"end":{"line":64,"column":49}}}) : helper)))
    + "\">\n                            <span>\n                                "
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":66,"column":32},"end":{"line":66,"column":40}}}) : helper)))
    + "\n                            </span>\n                        </a>\n                    </li>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <ul class=\"result-group result-devices\">\n                    <h3>"
    + container.escapeExpression((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(alias1,"page/search_results/devices",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":75,"column":24},"end":{"line":75,"column":67}}}))
    + "</h3>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"devices") : depth0),{"name":"each","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":76,"column":20},"end":{"line":87,"column":29}}})) != null ? stack1 : "")
    + "                </ul>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li>\n                        <a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"root") || (depth0 != null ? lookupProperty(depth0,"root") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"root","hash":{},"data":data,"loc":{"start":{"line":78,"column":33},"end":{"line":78,"column":41}}}) : helper)))
    + alias4(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":78,"column":41},"end":{"line":78,"column":49}}}) : helper)))
    + "\">\n                            <i class=\"image\">\n                                <img src=\""
    + alias4((lookupProperty(helpers,"resizeImage")||(depth0 && lookupProperty(depth0,"resizeImage"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"specs") : depth0)) != null ? lookupProperty(stack1,"deviceImage") : stack1),"64x64",{"name":"resizeImage","hash":{},"data":data,"loc":{"start":{"line":80,"column":42},"end":{"line":80,"column":83}}}))
    + "\" alt=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":80,"column":90},"end":{"line":80,"column":98}}}) : helper)))
    + "\">\n                            </i>                            \n                            <span>\n                                "
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":83,"column":32},"end":{"line":83,"column":40}}}) : helper)))
    + "\n                            </span>\n                        </a>\n                    </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- Search Results Template -->\n<div class=\"page search-results full\">\n    <div class=\"page-header\">\n        <div class=\"wrapper\">\n            "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"breadcrumbs") || (depth0 != null ? lookupProperty(depth0,"breadcrumbs") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"breadcrumbs","hash":{},"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":5,"column":29}}}) : helper))) != null ? stack1 : "")
    + "\n            <h1 class=\"page-title\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":6,"column":35},"end":{"line":6,"column":46}}}) : helper))) != null ? stack1 : "")
    + "</h1>\n        </div>\n    </div>\n    <div class=\"page-wrapper\">\n        <div class=\"results-list\">\n            <div class=\"result-filter widget\">\n                <ul>\n                    <li class=\"filter-item filter-all active\" data-filter=\"all\">\n                        <span>"
    + container.escapeExpression((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"page/search_results/results-filter/all",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":14,"column":30},"end":{"line":14,"column":84}}}))
    + "</span>\n                    </li>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"filter") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":20},"end":{"line":20,"column":29}}})) != null ? stack1 : "")
    + "                </ul>\n            </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"topics") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":12},"end":{"line":42,"column":19}}})) != null ? stack1 : "")
    + "            <div class=\"side-content\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"manufacturers") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":16},"end":{"line":58,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"softwares") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":59,"column":16},"end":{"line":72,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"devices") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":73,"column":16},"end":{"line":89,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    </div>\n</div>\n<!-- Search Results Template -->";
},"useData":true,"useDepths":true});